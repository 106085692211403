import { navigate } from "gatsby"
import React, { Component } from "react"
import { connect } from "react-redux"
import { checkUserExists } from "../../store/actions/authActions"
import { authStateInterface } from "../../store/reducers/authReducer"
import { utilsStateInterface } from "../../store/reducers/utilsReducer"
import Dashboard from "./Dashboard"

interface Props {
  location: any
  component: string
  authStore: authStateInterface
  utilsStore: utilsStateInterface
}

class AuthWrapper extends Component<Props> {
  componentDidMount() {
    this.handleCheckAuthNavigator()
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.authStore.userLoading !== this.props.authStore.userLoading ||
      prevProps.authStore.userAuth !== this.props.authStore.userAuth
    ) {
      this.handleCheckAuthNavigator()
    }
  }

  handleCheckAuthNavigator = async () => {
    if (!this.props.authStore.userLoading) {
      if (this.props.authStore.userAuth) {
        if (!this.props.authStore.user) {
          const userExists = await checkUserExists(
            this.props.utilsStore.firebase
          )
          if (!userExists) {
            navigate("/auth/signUp")
          }
        }
      } else {
        navigate("/")
      }
    }
  }

  render() {
    if (this.props.authStore.userLoading || !this.props.authStore.user) {
      return null
    } else {
      if (this.props.component === "Dashboard") {
        return <Dashboard location={this.props.location} />
      }
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  }
}

export default connect(mapStateToProps)(AuthWrapper)
